.carousel-container {
  width: 100%;
  height: 400px;
  border-radius: 60px 0 60px 60px;
  overflow: hidden;
  position: relative;
}

.carousel-container h3,
.carousel-container h4 {
  color: #FFF;
}

.carousel-container .slide {
  position: absolute;
  top: 0;
  left: 100%;
  padding: 84px 90px;
  height: 100%;
  width: 100%;
  transition: none;
  z-index: 0;
}

.carousel-container .slide.current {
  left: 0;
  z-index: 1;
}

.carousel-container .slide.fade-in {
  transition: left 1s ease;
  left: 0;
  z-index: 2;
}

.carousel-container .slide-0 {
  background-color: var(--violete-light);
}

.carousel-container .slide-1 {
  background-color: var(--blue);
}

.carousel-container .slide-2 {
  background-color: var(--orange);
}

.carousel-container .slide h3 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 120px;
}

.carousel-container .slide h4 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.carousel-container .arrows {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 30px;
  z-index: 5;
}

.carousel-container .arrows img {
  pointer-events: all;
  height: 42px;
  cursor: pointer;

  animation: 2s bounce 4s infinite;
}

@media only screen and (max-width: 1070px) {
  .carousel-container {
    border-radius: 20px 0 20px 20px;
    height: 270px;
  }

  .carousel-container .slide {
    padding: 20px;
  }

  .carousel-container .slide h3 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    padding: 20px 0;
  }

  .carousel-container .slide h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .carousel-container .arrows {
    padding: 40px 10px;
    align-items: start;
  }

  .carousel-container .arrows img {
    width: 28px;
  }
}

@media only screen and (max-width: 500px) {
  .carousel-container {
    border-radius: 20px 0 20px 20px;
    height: 350px;
  }
}
